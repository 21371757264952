// https://github.com/vueuse/vueuse/blob/main/packages/core/useBrowserLocation/index.ts
import { ref } from 'vue';

export interface BrowserLocationState {
  trigger: string;
  state?: any;
  length?: number;
  hash?: string;
  host?: string;
  hostname?: string;
  href?: string;
  origin?: string;
  pathname?: string;
  port?: string;
  protocol?: string;
  search?: string;
}
export function useBrowserLocation() {
  const buildState = (trigger: string): BrowserLocationState => {
    const { state, length } = window?.history || {};
    const { hash, host, hostname, href, origin, pathname, port, protocol, search } = window?.location || {};

    return {
      trigger,
      state,
      length,
      hash,
      host,
      hostname,
      href,
      origin,
      pathname,
      port,
      protocol,
      search,
    };
  };
  const state = ref(buildState('load'));
  window.addEventListener('popstate', () => {
    state.value = buildState('popstate');
  });

  window.addEventListener('hashchange', () => {
    state.value = buildState('hashchange');
  });

  return state;
}
