<template>
  <div class="service-type-tree" v-loading="loading">
    <el-tree
      ref="treeRef"
      :data="data"
      :props="defaultProps"
      default-expand-all
      :filter-node-method="filterMethod"
      :expand-on-click-node="false"
      :current-node-key="selectedNode"
      :highlight-current="selectedNode"
      @node-click="handleNodeClick"
    />
  </div>
</template>
<script>
import { ref, watch } from 'vue';
import { getCategoryTree } from '@/api/cim';
export default {
  name: 'ServiceTypeTree',
  props: {
    filterMethod: {
      type: Function,
      default: () => (value, data) => {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
    },
    value: {
      type: [Number, String],
      default: null,
    },
    begin: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const data = ref([]);
    const treeRef = ref();
    const selectedNode = ref(props.value);
    watch(
      () => props.value,
      () => {
        selectedNode.value = props.value;
      },
    );
    const defaultProps = {
      children: 'child',
      label: 'name',
    };
    const loading = ref(true);
    const fetchTree = async () => {
      loading.value = true;
      try {
        const { data: tree } = await getCategoryTree();
        data.value = tree;
      } catch (e) {
        console.log(e);
      }
      loading.value = false;
    };
    watch(
      () => props.begin,
      (nn) => {
        if (nn) {
          fetchTree();
        }
      },
    );
    if (props.begin) {
      fetchTree();
    }
    const handleNodeClick = (node) => {
      selectedNode.value = node.id;
      emit('change', node.id, node);
    };

    return {
      loading,
      data,
      treeRef,
      defaultProps,
      selectedNode,
      handleNodeClick,
    };
  },
};
</script>
<style lang="scss" scoped>
.service-type-tree {
  min-height: 120px;
}
:deep(.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content) {
  background-color: #ecf5ff;
  color: #006eff;
}
</style>
