import { getSpaceServiceTypes, validateUrl } from '@/api/cim';
import { ref, unref, watchEffect, Ref } from 'vue';
import { SpaceServiceType } from './types';

export function useSpaceService() {
  const typesList = ref<SpaceServiceType[]>([]);
  watchEffect(async () => {
    try {
      const { code, data, message } = await getSpaceServiceTypes();
      if (code === 0) {
        typesList.value = data as SpaceServiceType[];
      } else {
        throw new Error(message);
      }
    } catch (error) {
      console.log(error);
    }
  });

  const getTypeLabelById = (id: Ref<string> | string) => {
    const type = typesList.value.find((item) => item.id === unref(id));
    return type ? type.val : '';
  };
  return {
    typesList,
    getTypeLabelById,
  };
}

export async function validateSpaceServiceUrl(rule: any, value: any, callback: any) {
  try {
    const { code } = await validateUrl({
      url: value,
    });
    if (code !== 0) {
      callback(new Error('URL地址不合法'));
    } else {
      callback();
    }
  } catch (error: any) {
    callback(new Error(error.message));
    console.log(error);
  }
}
