<template>
  <div class="service-register" style="background: #fff; padding: 30px">
    <div class="service-register__steps">
      <el-steps :active="active" finish-status="success">
        <el-step title="基本信息" description="服务地址等基本配置信息"></el-step>
        <el-step title="选择目录" description="在服务目录中选择所属目录"></el-step>
        <el-step title="完成" description="完成注册服务，请在服务列表中查看" :status="lastStepStatus"></el-step>
      </el-steps>
    </div>
    <div class="service-register__content">
      <template v-if="active === 0">
        <div class="service-register__content_form">
          <el-form :model="registerData" label-width="80px" label-position="left" ref="formRef" :rules="rules">
            <el-form-item label="服务类型" prop="serviceTypeId">
              <el-select v-model="registerData.serviceTypeId" style="width: 100%">
                <el-option v-for="type in typesList" :key="type.id" :value="type.id" :label="type.val"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="服务地址" prop="serviceUrl">
              <el-input v-model="registerData.serviceUrl" type="url"></el-input>
              <el-tooltip class="box-item" effect="dark" :content="toolString" placement="right">
                <i class="el-icon-info" style="margin-left: 10px; font-size: 14px; position: absolute; top: 9px"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="口令">
              <el-radio-group v-model="hasToken">
                <el-radio label="0" size="large">无令牌</el-radio>
                <el-radio label="1" size="large">有令牌</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="令牌" prop="serviceToken">
              <el-input v-model="registerData.serviceToken" :disabled="hasToken === '0'"></el-input>
            </el-form-item>
            <el-form-item label="服务名称" prop="serviceName">
              <el-input v-model="registerData.serviceName"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template v-if="active === 1">
        <div class="service-register__content_tree">
          <service-type-tree @change="onSelectCatalog" />
        </div>
      </template>
      <template v-if="active === 2">
        <div class="service-register__content_success">
          <svg-icon icon-name="success" />
          <h1>操作成功</h1>
          <span>已成功注册服务</span>
          <div class="service-detail">
            <div class="service-detail__item">
              <div class="service-detail__item_label">服务名称:</div>
              <div class="service-detail__item_content">{{ registerData.serviceName }}</div>
            </div>
            <div class="service-detail__item">
              <div class="service-detail__item_label">地址:</div>
              <div class="service-detail__item_content">{{ registerData.serviceUrl }}</div>
            </div>
            <div class="service-detail__item">
              <div class="service-detail__item_label">代理地址:</div>
              <div class="service-detail__item_content">{{ proxyUrl }}</div>
            </div>
            <div class="service-detail__item">
              <div class="service-detail__item_label">服务类型:</div>
              <div class="service-detail__item_content">{{ getTypeLabelById(registerData.serviceTypeId) }}</div>
            </div>
          </div>
        </div>
      </template>
      <div class="service-register__content_button">
        <template v-if="active < 2">
          <el-button :disabled="active === 0" @click="handleBack">上一步</el-button>
          <el-button type="primary" @click="handleNext" :disabled="disableNext">下一步</el-button>
        </template>
        <template v-else>
          <el-button @click="handleContinueRegister" style="margin-right: 20px">继续注册服务</el-button>
          <router-link :to="{ path: 'space' }">
            <el-button type="primary">返回列表查看</el-button>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { registerSpaceService, validateUrl } from '@/api/cim';
import { ElMessage } from 'element-plus';
import { computed, reactive, ref, watch } from 'vue';
import ServiceTypeTree from '../../../service-resource-center/ServiceTypeTree';
import { useSpaceService } from './useSpaceService';
import { useBrowserLocation } from './useBrowserLocation';
export default {
  name: 'ServiceRegister',
  components: { ServiceTypeTree },
  setup() {
    const active = ref(0);
    const hasToken = ref('0');
    const proxyPath = ref('');
    const disableNext = ref(false);
    const registerData = reactive({
      serviceName: '',
      refCatalogId: null,
      serviceTypeId: '',
      serviceUrl: '',
      serviceToken: null,
    });

    const toolString = '服务子资源的URL地址，需设置为当前地址的子路径，或以"/"开头的相对路径，否则服务地址检查不通过';

    const tokeRules = [{ required: true, message: '令牌不能为空', trigger: 'blur' }];
    const rules = reactive({
      serviceTypeId: [{ required: true, message: '服务类型不能为空', trigger: 'change' }],
      serviceUrl: [{ required: true, message: '服务地址不能为空', trigger: 'blur' }],
      serviceName: [{ required: true, message: '服务名称不能为空', trigger: 'blur' }],
    });

    watch(hasToken, (v) => {
      if (v === '0') {
        registerData.serviceToken = null;
        rules.serviceToken = [];
      } else {
        rules.serviceToken = tokeRules;
      }
    });
    const formRef = ref();

    const handleBack = () => {
      active.value -= 1;
    };
    const validUrl = async () => {
      try {
        const params = {
          url: registerData.serviceUrl,
          serviceTypeId: registerData.serviceTypeId,
        };
        if (hasToken.value === '1') {
          params.token = registerData.serviceToken;
        }
        const { code } = await validateUrl(params);
        if (code === 0) {
          active.value += 1;
        }
      } catch (error) {
        console.log(error);
      }
    };
    const handleNext = async () => {
      disableNext.value = true;
      if (active.value === 0) {
        const valid = await formRef.value.validate();
        if (!valid) {
          return false;
        }
        await validUrl();
        disableNext.value = false;
      } else if (active.value === 1) {
        // 校验目录
        if (registerData.refCatalogId === null) {
          ElMessage.error('请选择目录');
        } else {
          // 提交注册
          try {
            const { code, data } = await registerSpaceService(registerData);
            if (code === 0) {
              active.value += 1; // finish
              proxyPath.value = data.proxyUrl;
            }
          } catch (error) {
            console.log(error);
          } finally {
            disableNext.value = false;
          }
        }
      }
    };

    // 继续注册服务，回到初始步骤
    const handleContinueRegister = () => {
      active.value = 0;
      formRef.value.resetFields();
    };

    // 获取服务类型列表
    const { typesList, getTypeLabelById } = useSpaceService();
    const onSelectCatalog = (catalogId) => {
      registerData.refCatalogId = catalogId;
    };
    const location = useBrowserLocation();

    const proxyUrl = computed(
      () => `${proxyPath.value.includes('http') ? '' : location.value.origin}${proxyPath.value}`,
    );

    const lastStepStatus = computed(() => {
      if (active.value < 2) {
        return 'wait';
      }

      return 'success';
    });
    return {
      registerData,
      active,
      handleNext,
      handleBack,
      handleContinueRegister,
      rules,
      hasToken,
      formRef,
      typesList,
      onSelectCatalog,
      getTypeLabelById,
      proxyUrl,
      lastStepStatus,
      disableNext,
      toolString,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.service-register__steps) {
  .el-step__icon.is-text {
    width: 30px;
    height: 30px;
  }
  .el-step__head.is-finish {
    .el-step__icon.is-text {
      background-color: #006eff;
      border: none;
    }
    .el-step__icon-inner {
      color: #fff;
    }
  }
  .el-step__main {
    .el-step__title.is-finish {
      color: #006eff;
    }
    .el-step__description.is-finish {
      color: #006eff;
    }
  }
}
.service-register__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .service-register__content_form {
    width: 35%;
    margin-top: 60px;
  }
  .service-register__content_button {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
  .service-register__content_tree {
    margin: 50px 0;
    margin-left: -100px;
  }
  .service-register__content_success {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    :deep(.svg-icon) {
      font-size: 60px;
    }
    .service-detail {
      margin-top: 30px;
      background-color: #f3f5f6;
      padding: 1em;
      max-width: 80vw;
      max-height: 400px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      overflow: auto;

      .service-detail__item {
        display: flex;
        font-size: 1rem;
        &:not(:last-child) {
          margin-bottom: 1em;
        }
        .service-detail__item_label {
          min-width: 100px;
          display: inline-block;
          white-space: nowrap;
          font-weight: bold;
          margin: 0 5px 0 10px;
        }
      }
    }
  }
}
</style>
